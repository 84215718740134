import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import PostCta from '../../components/marketing/postCta'
import Metatags from '../../components/marketing/metatags'

const Snippet = (props) => (
  <Layout>
    <Metatags
      title="Sidepath - Prism Web3 Newsletter"
      description="Web3 strategies for experience brands - Direct from the front lines to your inbox"
      image="https://picovideomedia.s3.amazonaws.com/static/logos/prism-marquee.png?t=2"
    />
    <hr size={1} />
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h1 class="display-2 font-weight-normal">
            Sidepath Prism Newsletter
          </h1>
          <p class="lead">
            Get the latest on Web3 strategies with the Sidepath Prism Newsletter
            &mdash; subscribe below.
          </p>
        </div>
      </div>
    </div>
    <PostCta context="emailSub" />
    <div class="my-4" />
  </Layout>
)

export default Snippet
